<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="number_of_people">
          <div>表示件数</div>
          <select v-model.number="numberOfPeople" name="" id="">
            <option value="5">5件</option>
            <option value="10">10件</option>
            <option value="50">50件</option>
            <option value="100">100件</option>
          </select>
          <CButton class="user-registration-btn" color="success" @click="businessPartnerRegistration"
            >取引先登録</CButton
          >
        </div>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="items"
          :fields="tableFields"
          :items-per-page="numberOfPeople"
          clickable-rows
          :active-page="activePage"
          @row-clicked="rowClicked"
          :pagination="{ doubleArrows: false, align: 'center' }"
        >
          <td slot="client_name" slot-scope="{ item }">
            <div class="name-ruby">{{ item.client_name_ruby }}</div>
            <div>{{ item.client_name }}</div>
          </td>
          <template #status="data">
            <td class="status">
              <CBadge :color="getBadge(data.item.status)">
                {{ data.item.status }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import firebase from "firebase/app";

const db = firebase.firestore();
// timestamp形式のデータをいい感じの形式に変換する関数
const convertFromTimestampToDatetime = (timestamp) => {
  const _d = timestamp ? new Date(timestamp * 1000) : new Date();
  const Y = _d.getFullYear();
  const m = (_d.getMonth() + 1).toString().padStart(2, "0");
  const d = _d.getDate().toString().padStart(2, "0");
  const H = _d.getHours().toString().padStart(2, "0");
  const i = _d.getMinutes().toString().padStart(2, "0");
  const s = _d.getSeconds().toString().padStart(2, "0");
  return `${Y}/${m}/${d}`;
  // return `${Y}/${m}/${d} ${H}:${i}:${s}`;
};

export default {
  name: "ClientList",
  components: {},
  data() {
    return {
      // adminName: "",
      accessPermission: "",
      name: "",
      comment: "",
      posts: [],
      items: [],
      numberOfPeople: 50,
      activePage: 1,
      tableFields: [
        { key: "company_name", label: "取引先名" },
        { key: "authentication_code", label: "認証用コード" },
        { key: "inviteCode", label: "招待コード" },
        { key: "company_number_of_user", label: "登録ユーザー数" },
        { key: "registrationDate", label: "登録日" },
        // { key: "status", label: "ステータス" },
      ],
    };
  },
  async created() {
    this.accessPermission = this.$store.state.clientUserData.clientUserData.authenticationCode;
    // console.log(this.accessPermission);
    // await this.getClientList();
    await db
      .collection("clients")
      .orderBy("clientData.companyName.ruby", "asc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          this.items.push({
            authentication_code: doc.data().authenticationCode,
            company_name: doc.data().clientData.companyName.name,
            registrationDate: convertFromTimestampToDatetime(doc.data().createdAt.seconds),
            inviteCode: doc.data().inviteCode,
            status: await this.countNotification(doc.data().inviteCode),
          });
          // localStorage.setItem('name', doc.data().clientData.name.name)
        });
        // console.log(this.items);
      });
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case "新着メッセージ":
          return "success";
        case "管理栄養士":
          return "info";
        case "":
          return "warning";
        case "未返信":
          return "danger";
        case "新規登録":
          return "primary";
        default:
          "primary";
      }
    },
    businessPartnerRegistration() {
      this.$router.push("business-partner-registration");
    },
    rowClicked(id) {
      this.$store.commit("getClientInviteCode", id.inviteCode);
      this.$router.push("user-list");
      // console.log(id.inviteCode);
      // this.$router.push("user-page");
    },
    getClientList() {
      db.collection("clients")
        .where("accessPermission", "==", this.accessPermission)
        .orderBy("clientData.companyName.ruby", "asc")
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.items.push({
              authentication_code: doc.data().authenticationCode,
              company_name: doc.data().clientData.companyName.name,
              registrationDate: convertFromTimestampToDatetime(doc.data().createdAt.seconds),
              inviteCode: doc.data().inviteCode,
            });
            // localStorage.setItem('name', doc.data().clientData.name.name)
          });
          // console.log(this.items);
        });
    },

    // 先にundefinedの値が返ってしまう。

    async countNotification(inviteCode) {
      if (inviteCode != undefined) {
        let notificationNumber = "";
        const querySnapshot = await db
          .collection("users")
          .where("inviteCode", "==", inviteCode)
          .where("status", "==", "新着メッセージ")
          .get();
        notificationNumber = querySnapshot.size;
        return notificationNumber;
      } else {
        return null;
      }
    },
    // async countNotification(inviteCode) {
    //     let test = "";
    //     const querySnapshot = await db.collection("users")
    //         .where("inviteCode", "==", inviteCode)
    //         .get()
    //         test = querySnapshot.size;
    //     return test;
    // },
    // async countNotification(clientID) {
    //     let test = "";
    //     await db
    //         .collection("clients")
    //         .doc(`${clientID}`)
    //         .get()
    //         .then((doc) => {
    //             test = doc.data().inviteCode;
    // console.log(doc.data());
    //         });
    //         return test;
    // },
  },
};
</script>
<style scoped>
.number_of_people {
  display: flex;
  padding-bottom: 10px;
}
.number_of_people div {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-left: solid 1px #ccc;
}
.number_of_people div:first-child {
  border-left: none;
}
select {
  border: solid 1px #d8dbe0;
  border-bottom: none;
  border-top: none;
}
.user-registration-btn {
  margin-left: 20px;
}
.status span {
  font-size: 0.9rem;
  height: 1.3rem;
}
.name-ruby {
  font-size: 0.5em;
}
.table th,
.table td {
  padding: 0.3rem;
}
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding: 0;
}
.card {
  margin-bottom: 0;
}
</style>
